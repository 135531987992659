<template>
  <div class="container mt-5 mb-5">
    <div class="row flex-nowrap m-0">
      <b-card :class="'recipe-search ' + (activeRecipe.id ? 'card col-7' : 'card col-12')">
        <b-card-header>
          Receptúry
          <div class="float-right">
            <b-button variant="empty" @click="onCreateRecipe">+ Vytvoriť novú receptúru</b-button>
          </div>
        </b-card-header>
        <b-input-group class="policy-search">
          <b-form-input @update="onSearchRecipes" debounce="500" type="search" v-model="searchInput"
                        placeholder="Vyhľadajte receptúru" />
          <svg class="search-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6164 10.9301L8.76034 7.95969C9.49467 7.08675 9.89702 5.98841 9.89702 4.845C9.89702 2.17351 7.72351 0 5.05203 0C2.38054 0 0.207031 2.17351 0.207031 4.845C0.207031 7.51648 2.38054 9.68999 5.05203 9.68999C6.05494 9.68999 7.01067 9.38749 7.82779 8.81326L10.7055 11.8062C10.8258 11.9311 10.9876 12 11.1609 12C11.325 12 11.4807 11.9374 11.5989 11.8237C11.85 11.5821 11.858 11.1814 11.6164 10.9301ZM5.05203 1.26391C7.02668 1.26391 8.63311 2.87034 8.63311 4.845C8.63311 6.81965 7.02668 8.42608 5.05203 8.42608C3.07737 8.42608 1.47094 6.81965 1.47094 4.845C1.47094 2.87034 3.07737 1.26391 5.05203 1.26391Z" fill="#C4C4C4"/>
          </svg>
          <b-button title="Filter receptúr" v-b-toggle.filter class="filter-btn"><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" y="12" width="11" height="1" rx="0.5" fill="#C4C4C4"/>
            <rect x="8" y="8" width="11" height="1" rx="0.5" fill="#C4C4C4"/>
            <rect x="8" y="16" width="11" height="1" rx="0.5" fill="#C4C4C4"/>
            <circle cx="9.5" cy="16.5" r="1.5" fill="#C4C4C4"/>
            <circle cx="17.5" cy="8.5" r="1.5" fill="#C4C4C4"/>
            <circle cx="13.5" cy="12.5" r="1.5" fill="#C4C4C4"/>
          </svg></b-button>
        </b-input-group>

        <b-collapse id="filter">
          <b-card>
            <div class="container">
              <div class="row">
                <div class="col-auto col-2p">
                  <h5><strong>Podľa diéty</strong></h5>
                  <b-form-checkbox-group v-model="searchFilters.diet" switches>
                    <template v-for="(switcher, i) in diet">
                      <b-form-checkbox :key="i" switch :value="switcher.id">{{ switcher.name }}</b-form-checkbox>
                    </template>
                  </b-form-checkbox-group>
                </div>
                <div class="col-auto col-8p">
                  <h5> <strong>Podľa kuchyne</strong></h5>
                  <b-form-checkbox-group v-model="searchFilters.cuisine" switches>
                    <template v-for="(switcher, i) in cuisine">
                      <b-form-checkbox :key="i" :value="switcher.id">{{ switcher.name }}</b-form-checkbox>
                    </template>
                  </b-form-checkbox-group>
                </div>
              </div>
            </div>

          </b-card>
        </b-collapse>

        <ul class="filter-tags list-unstyled d-inline-flex flex-wrap mb-0">
          <li @click="onRemoveFilter('diet', tag)" v-for="tag in searchFilters.diet" :key="`diet-${tag}`" :id="`diet-${tag}`"
              class="text-nowrap">
            {{ getRecipesSearchFilterName('diet', tag) }}
            <b-button variant="link" size="sm"><svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.50564 1.80981L4.68771 3.62774L6.48988 5.4299C6.78004 5.72007 6.77799 6.19258 6.48528 6.48529C6.19258 6.77799 5.72006 6.78005 5.4299 6.48988L3.62773 4.68772L1.80981 6.50565C1.50875 6.75065 1.07319 6.73005 0.801077 6.45794C0.528967 6.18583 0.508366 5.75026 0.753368 5.44921L2.56559 3.63698L0.769082 1.84047C0.526693 1.54153 0.551088 1.10577 0.825578 0.831282C1.10007 0.556792 1.53583 0.532398 1.83476 0.774787L3.63127 2.5713L5.4492 0.753374C5.75026 0.508373 6.18582 0.528973 6.45793 0.801083C6.73004 1.07319 6.75064 1.50876 6.50564 1.80981Z" fill="#990F0F"/>
            </svg></b-button>
          </li>
          <li @click="onRemoveFilter('cuisine', tag)" v-for="tag in searchFilters.cuisine" :key="`cuisine-${tag}`" :id="`cuisine-${tag}`"
              class="text-nowrap">
            {{ getRecipesSearchFilterName('cuisine', tag) }}
            <b-button variant="link" size="sm"><svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.50564 1.80981L4.68771 3.62774L6.48988 5.4299C6.78004 5.72007 6.77799 6.19258 6.48528 6.48529C6.19258 6.77799 5.72006 6.78005 5.4299 6.48988L3.62773 4.68772L1.80981 6.50565C1.50875 6.75065 1.07319 6.73005 0.801077 6.45794C0.528967 6.18583 0.508366 5.75026 0.753368 5.44921L2.56559 3.63698L0.769082 1.84047C0.526693 1.54153 0.551088 1.10577 0.825578 0.831282C1.10007 0.556792 1.53583 0.532398 1.83476 0.774787L3.63127 2.5713L5.4492 0.753374C5.75026 0.508373 6.18582 0.528973 6.45793 0.801083C6.73004 1.07319 6.75064 1.50876 6.50564 1.80981Z" fill="#990F0F"/>
            </svg></b-button>
          </li>
        </ul>

        <b-overlay :show="busySearch" rounded="lg">
          <template v-if="recipesSearched.length && searchInput">
            <b-table :tbody-tr-class="rowClass" :current-page="pager.recipesSearchedPage" :per-page="pager.perPage"
                     striped hover :items="recipesSearched" :fields="fields" class="search-table recipes-table">
              <template #head(name)>
                Výsledok vyhľadávania ({{recipesSearched.length}})
              </template>
              <template #cell(liked)="value">
                <LikeBadge :liked="value.item.liked" :recipe-id="value.item.id" />
              </template>

              <template #cell(name)="value">
                <div class="pointer-event" @click="onRowSelected(value.item.id)">
                  {{value.item.name}}
                </div>
              </template>

              <template #cell(owned)="value">
                <div class="my-badge" v-if="value.value" size="sm" variant="tertiary">Moja</div>
              </template>

              <template #cell(actions)="value">
                <template>
                  <b-dropdown right class="policy-drop" no-caret variant="link" :ref="`dropdown${value.item.id}`">
                    <template #button-content>
                      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="3" width="2" height="8" rx="1" fill="white"/>
                        <rect y="5" width="2" height="8" rx="1" transform="rotate(-90 0 5)" fill="white"/>
                      </svg>

                    </template>
                    <b-dropdown-form form-class="p-0 add-dropdown">
                      <b-nav pills small justified class="mb-2">
                        <b-nav-item @click="onSwitchAddDropDown('daily')"
                                    :active="addDropDownActive === 'daily'">Denné menu</b-nav-item>
                        <b-nav-item @click="onSwitchAddDropDown('carte')"
                                    :active="addDropDownActive === 'carte'">À la carte</b-nav-item>
                        <b-nav-item @click="onSwitchAddDropDown('catering')"
                                    :active="addDropDownActive === 'catering'">Catering</b-nav-item>
                      </b-nav>
                      <b-form-group class="mb-0" v-show="addDropDownActive === 'daily'">
                        <b-calendar block hide-header no-key-nav start-weekday="1" label-help="" class="border-0"
                                    :min="minDate" @selected="onAddToDaily($event, value.item.id)" locale="sk-SK"></b-calendar>
                      </b-form-group>
                      <b-form-group class="mb-0" v-show="addDropDownActive === 'carte'">
                        <template v-for="(btn, i) in carte">
                          <b-button block align="left" :key="i" @click="onAddToCarte(btn.id, value.item.id)">{{btn.name}}</b-button>
                        </template>
                      </b-form-group>
                      <b-form-group class="mb-0" v-show="addDropDownActive === 'catering'">
                        <template v-for="(btn, i) in catering">
                          <b-button block align="left" :key="i" @click="onAddToCatering(btn.id, value.item.id)">{{btn.name}}</b-button>
                        </template>
                      </b-form-group>
                    </b-dropdown-form>
                  </b-dropdown>
                </template>
              </template>
            </b-table>
            <b-pagination v-if="recipesSearched.length > pager.perPage"
                v-model="pager.recipesSearchedPage" limit="10"
                :per-page="pager.perPage" :total-rows="recipesSearched.length"
                align="center" class="mt-3"></b-pagination>
          </template>

          <div v-if="!recipesSearched.length && searchInput.length > 2">
            <NothingFound>
              <template slot="title">Pre hľadaný výraz sa nenašiel žiaden výsledok</template>
            </NothingFound>
          </div>
        </b-overlay>

        <b-table v-if="recipesOwned.length && !recipesSearched.length" :current-page="pager.recipesOwnedPage" :per-page="pager.perPage/2" :tbody-tr-class="rowClass"
                 striped hover :items="recipesOwned" :fields="fields" class="search-table recipes-table">
          <template #head(name)>
            Moje ({{recipesOwned.length}})
          </template>
          <template #cell(liked)="value">
            <LikeBadge :liked="value.item.liked" :recipe-id="value.item.id" />
          </template>

          <template #cell(name)="value">
            <div class="pointer-event" @click="onRowSelected(value.item.id)">
              {{value.item.name}}
            </div>
          </template>


          <template #cell(owned)="value">
            <div class="my-badge" v-if="value.value" size="sm" variant="tertiary">Moja</div>
          </template>


          <template #cell(actions)="value">
            <template>
              <b-dropdown right class="policy-drop" no-caret variant="link" :ref="`dropdown${value.item.id}`">
                <template #button-content>
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3" width="2" height="8" rx="1" fill="white"/>
                    <rect y="5" width="2" height="8" rx="1" transform="rotate(-90 0 5)" fill="white"/>
                  </svg>

                </template>
                <b-dropdown-form form-class="p-0 add-dropdown">
                  <b-nav pills small justified class="mb-2">
                    <b-nav-item @click="onSwitchAddDropDown('daily')"
                                :active="addDropDownActive === 'daily'">Denné menu</b-nav-item>
                    <b-nav-item @click="onSwitchAddDropDown('carte')"
                                :active="addDropDownActive === 'carte'">À la carte</b-nav-item>
                    <b-nav-item @click="onSwitchAddDropDown('catering')"
                                :active="addDropDownActive === 'catering'">Catering</b-nav-item>
                  </b-nav>
                  <b-form-group class="mb-0" v-show="addDropDownActive === 'daily'">
                    <b-calendar block hide-header no-key-nav start-weekday="1" label-help="" class="border-0"
                                :min="minDate" @selected="onAddToDaily($event, value.item.id)" locale="sk-SK"></b-calendar>
                  </b-form-group>
                  <b-form-group class="mb-0" v-show="addDropDownActive === 'carte'">
                    <template v-for="(btn, i) in carte">
                      <b-button block align="left" :key="i" @click="onAddToCarte(btn.id, value.item.id)">{{btn.name}}</b-button>
                    </template>
                  </b-form-group>
                  <b-form-group class="mb-0" v-show="addDropDownActive === 'catering'">
                    <template v-for="(btn, i) in catering">
                      <b-button block align="left" :key="i" @click="onAddToCatering(btn.id, value.item.id)">{{btn.name}}</b-button>
                    </template>
                  </b-form-group>
                </b-dropdown-form>
              </b-dropdown>
            </template>
          </template>
        </b-table>
        <b-pagination v-if="recipesOwned.length > (pager.perPage/2) && !recipesSearched.length"
                      v-model="pager.recipesOwnedPage" limit="10"
                      :per-page="pager.perPage/2" :total-rows="recipesOwned.length"
                      align="center" class="mt-3"></b-pagination>

        <b-table v-if="recipesLiked.length && !recipesSearched.length" :current-page="pager.recipesLikedPage" :per-page="pager.perPage/2" :tbody-tr-class="rowClass"
                 striped hover :items="recipesLiked" :fields="fields" class="search-table recipes-table">
          <template #head(name)>
            Obľúbené ({{recipesLiked.length}})
          </template>
          <template #cell(liked)="value">
            <LikeBadge :liked="value.item.liked" :recipe-id="value.item.id" />
          </template>

          <template #cell(name)="value">
            <div class="pointer-event" @click="onRowSelected(value.item.id)">
              {{value.item.name}}
            </div>
          </template>

          <template #cell(owned)="value">
            <div class="my-badge" v-if="value.value" size="sm" variant="tertiary">Moja</div>
          </template>

          <template #cell(actions)="value">
            <template>
              <b-dropdown right class="policy-drop" no-caret variant="link" :ref="`dropdown${value.item.id}`">
                <template #button-content>
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3" width="2" height="8" rx="1" fill="white"/>
                    <rect y="5" width="2" height="8" rx="1" transform="rotate(-90 0 5)" fill="white"/>
                  </svg>

                </template>
                <b-dropdown-form form-class="p-0 add-dropdown">
                  <b-nav pills small justified class="mb-2">
                    <b-nav-item @click="onSwitchAddDropDown('daily')"
                                :active="addDropDownActive === 'daily'">Denné menu</b-nav-item>
                    <b-nav-item @click="onSwitchAddDropDown('carte')"
                                :active="addDropDownActive === 'carte'">À la carte</b-nav-item>
                    <b-nav-item @click="onSwitchAddDropDown('catering')"
                                :active="addDropDownActive === 'catering'">Catering</b-nav-item>
                  </b-nav>
                  <b-form-group class="mb-0" v-show="addDropDownActive === 'daily'">
                    <b-calendar block hide-header no-key-nav start-weekday="1" label-help="" class="border-0"
                                :min="minDate" @selected="onAddToDaily($event, value.item.id)" locale="sk-SK"></b-calendar>
                  </b-form-group>
                  <b-form-group class="mb-0" v-show="addDropDownActive === 'carte'">
                    <template v-for="(btn, i) in carte">
                      <b-button block align="left" :key="i" @click="onAddToCarte(btn.id, value.item.id)">{{btn.name}}</b-button>
                    </template>
                  </b-form-group>
                  <b-form-group class="mb-0" v-show="addDropDownActive === 'catering'">
                    <template v-for="(btn, i) in catering">
                      <b-button block align="left" :key="i" @click="onAddToCatering(btn.id, value.item.id)">{{btn.name}}</b-button>
                    </template>
                  </b-form-group>
                </b-dropdown-form>
              </b-dropdown>
            </template>
          </template>
        </b-table>
        <b-pagination v-if="recipesLiked.length > (pager.perPage/2) && !recipesSearched.length"
                      v-model="pager.recipesLikedPage" limit="10"
                      :per-page="pager.perPage/2" :total-rows="recipesLiked.length"
                      align="center" class="mt-3"></b-pagination>
      </b-card>
      <b-card class="col-5 ml-2 recipe-content" v-if="activeRecipe.id">
        <b-card-header>
          {{activeRecipe.name}}
          <ul class="filter-tags list-unstyled d-inline-flex flex-wrap mb-0">
            <li v-for="tag in activeRecipe.diets" :key="`diet-${tag.id}`" :id="`diet-${tag.id}`"
                class="text-nowrap">
              {{ tag.name }}
            </li>
            <li v-for="tag in activeRecipe.cuisines" :key="`cuisine-${tag.id}`" :id="`cuisine-${tag.id}`"
                class="text-nowrap">
              {{ tag.name }}
            </li>
          </ul>
        </b-card-header>
        <b-card-body>
          <h5>Suroviny</h5>
          <ul class="list-unstyled goods-list">
            <li v-for="(item, i) in activeRecipe.ingredients" :key="'ingredient'+i" class="d-flex justify-content-around">
              <div class="flex-grow-1">{{item.name}}</div>
              <div class="text-right">{{formatQuantity(item)}}</div>

            </li>
          </ul>
          <template v-if="activeRecipe.procedure_steps.length">
            <h5>Postup</h5>
            <ul class="list-unstyled process-list">
              <li v-for="(step, i) in activeRecipe.procedure_steps" :key="'step'+i" class="d-flex">
                <div class="step-number">{{i+1}}</div>
                <div class="step-content">
                  {{step}}
                </div>
              </li>
            </ul>
          </template>
          <template v-if="activeRecipe.images.length">
            <CoolLightBox
                :items="images"
                :index="imageIndex"
                @close="imageIndex = null">
            </CoolLightBox>
            <h5>Obrázky</h5>
            <div>
              <ul class="recipe-images list-unstyled">
                <li v-for="(image, i) in activeRecipe.images" :key="'image'+i"
                    :style="{backgroundImage: `url(${image.path})`}"
                    class="d-flex rounded overflow-hidden mr-1" @click="imageIndex = i" />

              </ul>
            </div>
          </template>
        </b-card-body>
        <b-card-footer>
          <div class="footer-price">

          </div>
          <div class="float-right">
            <LikeBadge :liked="activeRecipe.liked" :recipe-id="activeRecipe.id" />

            <b-button class="ml-2" v-if="!activeRecipe.owned" @click.prevent="onDuplicateRecipe(activeRecipe.id)">Upraviť</b-button>
            <b-button class="ml-2" v-if="activeRecipe.owned" @click.prevent="onEditRecipe(activeRecipe.id)">Upraviť</b-button>



            <b-dropdown right dropup class="policy-drop" no-caret variant="link" :ref="`dropdown${activeRecipe.id}`">
              <template #button-content>
                Pridať
              </template>
              <b-dropdown-form form-class="p-0 add-dropdown">
                <b-nav pills small justified class="mb-2">
                  <b-nav-item @click="onSwitchAddDropDown('daily')"
                              :active="addDropDownActive === 'daily'">Denné menu</b-nav-item>
                  <b-nav-item @click="onSwitchAddDropDown('carte')"
                              :active="addDropDownActive === 'carte'">À la carte</b-nav-item>
                  <b-nav-item @click="onSwitchAddDropDown('catering')"
                              :active="addDropDownActive === 'catering'">Catering</b-nav-item>
                </b-nav>
                <b-form-group class="mb-0" v-show="addDropDownActive === 'daily'">
                  <b-calendar block hide-header no-key-nav start-weekday="1" label-help="" class="border-0"
                              :min="minDate" @selected="onAddToDaily($event, activeRecipe.id)" locale="sk-SK"></b-calendar>
                </b-form-group>
                <b-form-group class="mb-0" v-show="addDropDownActive === 'carte'">
                  <template v-for="(btn, i) in carte">
                    <b-button block align="left" :key="i" @click="onAddToCarte(btn.id, activeRecipe.id)">{{btn.name}}</b-button>
                  </template>
                </b-form-group>
                <b-form-group class="mb-0" v-show="addDropDownActive === 'catering'">
                  <template v-for="(btn, i) in catering">
                    <b-button block align="left" :key="i" @click="onAddToCatering(btn.id, activeRecipe.id)">{{btn.name}}</b-button>
                  </template>
                </b-form-group>
              </b-dropdown-form>
            </b-dropdown>
            <template v-if="!demoDomain">
              <b-dropdown right class="policy-drop dotdrop" v-if="activeRecipe.owned">
                <template #button-content>
                  <span>...</span>
                </template>
                <b-dropdown-item v-if="activeRecipe.owned" @click.prevent="onDeleteRecipe(activeRecipe.id)">Vymazať</b-dropdown-item>
              </b-dropdown>
            </template>
          </div>
        </b-card-footer>
      </b-card>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {mapActions, mapGetters, mapMutations} from "vuex"
  import LikeBadge from "@/plugins/app/recipes/like-badge"
  import CoolLightBox from 'vue-cool-lightbox'
  import format from "@/plugins/app/formatters"
  import NothingFound from "@/plugins/app/_layouts/components/nothing-found"

  export default Vue.extend({
    components: {LikeBadge, CoolLightBox, NothingFound},
    props: ['vendorId'],
    data: function () {
      return {
        minDate: format.dayId(new Date()),
        addDropDownActive: 'daily',
        searchInput: '',
        imageIndex: null,
        pager: {
          perPage: 20,
          recipesSearchedPage: 1,
          recipesLikedPage: 1,
          recipesOwnedPage: 1,
        },
        searchFilters: {
          diet: [],
          cuisine: []
        },
        fields: [
          { key: 'liked', label: ''},
          { key: 'name', label: 'Label'},
          { key: 'owned', label: ''},
          { key: 'actions', label: ''},
        ]
      }
    },
    watch: {
      searchFilters: {
        handler: function (filters) {
          this.updateSearchFilter(filters)
          this.onSearchRecipes(this.searchInput)
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters('carte', ['carte']),
      ...mapGetters('catering', ['catering']),
      ...mapGetters('recipes', ['recipesSearched', 'recipesOwned', 'recipesLiked', 'activeRecipe', 'busySearch']),
      ...mapGetters('enums', ['cuisine', 'diet']),
      demoDomain() {
        return process.env.VUE_APP_DEMO_DOMAIN === document.location.host
      },
      images: function () {
        const map = []
        if(!this.activeRecipe.images)
          return []
        else {
          this.activeRecipe.images.forEach( i => map.push(i.path))
          return map
        }
      },
    },
    beforeDestroy() {
      this.$root.$off('clearRecipesSearch', this._clearSearch)
      this._clearSearch()
    },
    async mounted() {
      this.$root.$on('clearRecipesSearch', this._clearSearch)

      await this.loadRecipes()
      await this.preloadCatering()
      await this.preloadCarte()
      const id = this.$route.params.id
      if (id)
        await this.loadRecipe(id)
    },
    methods: {
      ...mapMutations('recipes', [
          'clearSearchRecipes', 'updateSearchFilter',
          'clearSearchRecipesFilters', 'clearActiveRecipe',
      ]),
      ...mapActions('recipes', [
          'searchRecipes', 'loadRecipe',
          'loadRecipes', 'removeRecipe',
      ]),
      ...mapActions('carte', ['addRecipeToCarte', 'preloadCarte']),
      ...mapActions('catering', ['addRecipeToCatering', 'preloadCatering']),
      ...mapActions('daily', ['addRecipeToDaily', 'loadDaily']),

      onRowSelected(id) {
        if(this.activeRecipe.id !== id) {
          this.loadRecipe(id)
          this.$router.replace(`/app/recipes/${id}`)
        } else {
          this.clearActiveRecipe()
          this.$router.replace(`/app/recipes/`)
        }
      },
      onSearchRecipes: function (s) {

        if(s.length < 3) {
          this.clearSearchRecipes()
          return
        }
        this.searchRecipes(s)
        this.pager.recipesSearchedPage = 1
      },
      onSwitchAddDropDown( section ) {
        this.addDropDownActive = section
      },
      async onAddToDaily(id, recipeId) {
        await this.loadDaily({dailyId: id})
        await this.addRecipeToDaily({id, recipeId}).then(_ => this.$wToast.success('Recept bol pridaný'))
        this._closeModal(recipeId)
      },
      onAddToCarte (id, recipeId) {
        this.addRecipeToCarte({id, recipeId}).then(_ => this.$wToast.success('Recept bol pridaný'))
        this._closeModal(recipeId)
      },
      onAddToCatering (id, recipeId) {
        this.addRecipeToCatering({id, recipeId}).then(_ => this.$wToast.success('Recept bol pridaný'))
        this._closeModal(recipeId)
      },
      onRemoveFilter (type, id) {
        this.searchFilters[type] = this.searchFilters[type].filter(e => e !== id)
        this.updateSearchFilter(this.searchFilters)
        this.onSearchRecipes(this.searchInput)
      },

      onDuplicateRecipe(id) {
        this.$router.push(`/app/recipe-duplicate/${id}`)
      },
      onDeleteRecipe(id) {
        this.removeRecipe(id)
      },
      onEditRecipe(id) {
        this.$router.push(`/app/recipe-edit/${id}`)
      },
      onCreateRecipe() {
        this.$router.push('/app/recipe-create')
      },

      getRecipesSearchFilterName(type, id) {
        return this[type].filter(e => e.id == id)[0].name
      },
      rowClass(item) {
        return this.activeRecipe.id === item.id ? 'row-selected' : ''
      },
      _closeModal(recipeId) {
        this.$refs[`dropdown${recipeId}`].hide()
      },
      _clearSearch() {
        this.clearSearchRecipesFilters()
        this.clearSearchRecipes()
        this.clearActiveRecipe()
        this.searchInput = ''
      },
      formatPrice: format.price,
      formatQuantity: format.quantity,
    }
  })
</script>
<style lang="scss">
.recipe-search {
  //min-height: 600px;
}

.recipe-content{
  padding-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  z-index:10;
  min-height: 600px;
  max-height: calc(100vh - 20px);
  .card-footer {
    padding-left: 0;
    padding-right: 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-link:hover {
      text-decoration: none;
      background-color: #109c48 !important; // todo: fuj, toto dat prec
    }

    .footer-price{
      font-size: 12px;
      font-weight: normal;
      color:#6A6A6A;
      strong{
        font-size: 14px;
        font-weight: bold;
        color: #000000;
      }
    }
    .dropup {
      .dropdown-toggle-no-caret{
        background-color: #13bf58;
        color: #ffffff;
      }
    }
    .dotdrop{
      button {
        background: transparent;
      }
    }
    .policy-drop {
      &:nth-child(2){
        margin-left: 15px;
      }
      &:nth-child(3){
        margin-left: 5px;
      }

      button{
        border: none;
        span{
          display: block;
          margin-top: -8px;
          color: #a6a6a6;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  .card-header {
    display: flex;
    flex-direction: column;
    .filter-tags {
      padding-bottom: 0;
      li {
        padding: 7px 10px;
      }
    }
  }
  .card-body {
    overflow-y: auto;
    h5 {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    ul.goods-list {
      padding-bottom: 12px;
      margin-bottom: 17px;
      border-bottom: 1px solid #F1F1F1;
      li {
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 8px;
        div{
          &:nth-child(2){
            width: 20%;
          }
          &:nth-child(3){
            width: 20%;
            text-align: right;
          }
        }
      }
    }
    ul.process-list {
      padding-bottom: 12px;
      margin-bottom: 17px;
      border-bottom: 1px solid #F1F1F1;
      li {
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 15px;
        color: #6A6A6A;
        .step-number {
          min-width: 20px;
          font-size: 11px;
          font-weight: 500;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color:#ECECEC;
          color: #000000;
        }
        .step-content {
          padding-left: 15px;
        }
      }
    }
  }
}
  .add-dropdown {
    width: 330px;
  }

  .recipe-images{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
    padding: 0;
    border: 0;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 5px;

    li {
      background-position: center center;
      background-size: cover;
      padding-top: 100%;
    }
  }

  table.recipes-table {
    tbody {
      tr {
        &.row-selected{
          background: #E7F9EE !important;
        }

        &.edited td{
          background: #fff7de !important;
        }
        border-radius: 4px;
        .tr-indent{
          &:after{
            content:'';
            height: 5px;
            display: block;
          }
        }
        &:nth-of-type(odd){
          background-color: #ffffff;
        }
        td{
          &:nth-child(1){
            width: 2.25%;
            padding-right: 0;
          }
          &:nth-child(2){
            width: 80%;
            font-weight: 500;
          }
          &:nth-child(3){
            text-align: right;
            .my-badge{
              display: inline-block;
              border-radius: 4px;
              font-weight: 500;
              font-size: 10px;
              color: #990F0F;
              line-height: 1;
              padding: 7px 10px;
              background-color: #F2D0D0;
              border: none;
            }
          }


          &:nth-child(4){
            padding-left: 0;
            width: 2%;
            button.dropdown-toggle-no-caret{
              background-color: #13BF58;
              border: none;
              padding: 6px;
              display: flex;
              &:after{
                display: none;
              }
              &:focus{
                box-shadow: none;
                &:active{
                  box-shadow: none;
                }
              }
              &:active{
                background-color: transparent;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
</style>
