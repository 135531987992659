<template>
  <svg @click="onToggleRecipeLike" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :style="{fill: !!liked ? '#BF1313' : '#C4C4C4'}" d="M8.64601 0C8.03134 0 7.4678 0.194779 6.97107 0.578938C6.49486 0.947234 6.1778 1.41633 5.99114 1.75743C5.80448 1.41631 5.48742 0.947234 5.01121 0.578938C4.51448 0.194779 3.95094 0 3.33627 0C1.62096 0 0.327423 1.40303 0.327423 3.26358C0.327423 5.27363 1.94121 6.64887 4.38426 8.7308C4.79913 9.08436 5.26937 9.48511 5.75813 9.91252C5.82256 9.96894 5.9053 10 5.99114 10C6.07698 10 6.15972 9.96894 6.22415 9.91254C6.71295 9.48507 7.18317 9.08434 7.59828 8.73058C10.0411 6.64887 11.6549 5.27363 11.6549 3.26358C11.6549 1.40303 10.3613 0 8.64601 0Z"/>
  </svg>
</template>
<script>
import Vue from 'vue'
import {mapActions} from 'vuex'

export default Vue.extend({
  props: {
    liked: Boolean,
    recipeId: Number
  },
  methods: {
    ...mapActions('recipes', ['toggleRecipeLike']),
    onToggleRecipeLike() {
      this.toggleRecipeLike([{id: this.recipeId}])
    }
  }
})
</script>
<style lang="scss" scoped>
svg{
  cursor: pointer;
}
</style>
